import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import fire from "../../images/pyramid/fire-outline.svg";
import support from "../../images/pyramid/support-outline.svg";
import map from "../../images/pyramid/map-outline.svg";
import triangle from "../../images/pyramid/triangle.svg";
import scrollToElement from '../../helpers/scrollToElement';

const Pyramid4 = (props) =>(
    <section className="pyramid-6">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Dodatkowe zalety Piramidy:</h2>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}}  md={{size: 1, offset: 1}}>
                    <img src={fire} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>SPALASZ MAKSYMALNĄ ILOŚĆ KALORII</strong><br/>
                    Trening ułożony na bazie ćwiczeń, które w tym samym czasie angażują jak najwięcej mięśni, a tym samym maksymalizują spalanie kalorii.</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={support} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>NIE TRACISZ MOTYWACJI I ZAPAŁU DO ĆWICZEŃ</strong><br/>
                    Dobrze wiemy, jak ważne są dla Ciebie szybko widoczne efekty: one nie tylko cieszą, ale także doskonale motywują do dalszego działania!</p>
                </Col>
            </Row>

            <Row className="why">
                <Col xs={{size:3, offset: 0}} md={{size: 1, offset: 1}}>
                    <img src={map} className="img-fluid" alt="" />
                </Col>
                <Col xs="9" md="9">
                    <p><strong>ĆWICZYSZ GDZIE I KIEDY CHCESZ</strong><br/>
                    To Ty ustalasz grafik treningowy! Chcesz ćwiczyć w domu, w parku lub na wakacjach? Możesz to zrobić nawet bez dostępu do internetu!</p>
                </Col>
            </Row>

            <Row className="d-md-none mb-5">
                <Col>
                    <div className="line mt-5">
                        <span>
                            <div
                                className="round-button"
                                onClick={() =>
                                    scrollToElement(
                                        document.getElementById('piramida2')
                                    )
                                }
                                aria-hidden="true" 
                            >
                                <img src={triangle} alt="" />
                            </div>         
                        </span>
                    </div>
                </Col>
            </Row>

            <Row id="piramida2">
                <Col md={{size: 10, offset: 1}}>
                    <p>Sama widzisz, że trening nie musi być długi ani skomplikowany, żeby zapewnić Ci wymarzone efekty - ważniejszy jest dobrze przemyślany plan. To właśnie dzięki niemu spełnisz swoje marzenia o szczupłej sylwetce i w końcu pokochasz swoje ciało!</p>

                    <div className="line mt-5 d-none d-md-block">
                        <span className="d-inline">
                            <a href={props.url} className="btn btn-primary">Pobierz aplikację</a>
                        </span>
                    </div>

                    <a href={props.url} className="btn btn-primary d-md-none mt-5">Pobierz aplikację</a>
                </Col>
            </Row>
                          
        </Container>
    </section>
);

export default Pyramid4;
