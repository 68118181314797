import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import double_triangle from "../../images/pyramid/double_triangle.svg";

const Pyramid4 = () =>(
    <section className="pyramid-5">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Dlaczego akurat ten trening jest taki skuteczny?</h2>
                    <p>Szybki progres jest możliwy tylko dzięki ciągłemu bodźcowaniu mięśni. Zapewnij im wystarczającą stymulację, a osiągniesz szybko widoczne efekty. W treningu Piramidy zrobisz to aż na 2 sposoby: stopniowo zwiększając, a następnie zmniejszając intensywność i czas trwania sekwencji oraz wykonując różnorodne ćwiczenia.</p>
                </Col>
            </Row>
            <Row className="d-md-none grey-bg align-items-center">
                <Col xs="4">
                    <img src={double_triangle} className="img-fluid" alt="" />
                </Col>
                <Col xs="8">
                    <p>Piramida to specjalny trening wzmacniający całe ciało oraz spalający tkankę tłuszczową.</p>
                </Col>
            </Row>            
            <Row className="d-none d-md-block">
                <Col md={{size: 10, offset: 1}}>
                    <p>Piramida to specjalny trening wzmacniający całe ciało oraz spalający tkankę tłuszczową, a właśnie przy takim połączeniu możemy zobaczyć najszybsze efekty. Gwarantuję Ci, że Twoje mięśnie pokochają ten program, a Ty poczujesz ogromną satysfakcję i natychmiastowy przypływ energii!</p>
                </Col>
            </Row>
            <Row className="d-md-none">
                <Col md={{size: 10, offset: 1}}>
                    <p>Właśnie przy takim połączeniu możemy zobaczyć najszybsze efekty. Gwarantuję Ci, że Twoje mięśnie pokochają ten program, a Ty poczujesz ogromną satysfakcję i natychmiastowy przypływ energii!</p>
                </Col>
            </Row>                
        </Container>
    </section>
);

export default Pyramid4;
