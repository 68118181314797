import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Pyramid2 = () =>(
    <section className="pyramid-2">
        <Container>
            <Row>
                <Col md={{size: 10, offset: 1}}>
                    <h2>Zrób ten trening, by nabrać energii i szybko spalić tkankę tłuszczową</h2>
                    <p>Piramida to rodzaj treningu progresywnego. Polega na wykonywaniu naprzemiennie kilku ćwiczeń w stopniowo zmieniającym się czasie. Właśnie dzięki temu Twoje ciało ma siłę na więcej!</p>
                    <p>Z tą sprawdzoną metodą 12-minutowych treningów, możesz osiągnąć takie same efekty jak podczas długich, 45-minutowych sesji na siłowni.</p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Pyramid2;
