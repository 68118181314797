import React, { Component }  from 'react';
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import Seo from "../components/seo";
import AppBar from "../components/Pyramid/AppBar";
import Hero from "../components/Pyramid/Hero";
import Pyramid1 from "../components/Pyramid/Pyramid1";
import Pyramid2 from "../components/Pyramid/Pyramid2";
import Pyramid3 from "../components/Pyramid/Pyramid3";
import Pyramid4 from "../components/Pyramid/Pyramid4";
import Pyramid5 from "../components/Pyramid/Pyramid5";
import Pyramid6 from "../components/Pyramid/Pyramid6";
import Pyramid7 from "../components/Pyramid/Pyramid7";
import Footer from "../components/Pyramid/Footer";
import 'url-search-params-polyfill';
import { graphql } from "gatsby";
import OneLinkUrlGenerator from "../helpers/onelink-smart-script";

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = { url: ''};
    }

    async componentDidMount(){
        this.buildURLfromQueryString();
    }

    buildURLfromQueryString(){
        const onelinkGenerator =  new OneLinkUrlGenerator({
            oneLinkURL: "https://diet-training.onelink.me/17FA/",
            pidKeysList: ['af_pid', 'utm_source'],
            campaignKeysList: ['af_c', 'utm_campaign'],
            pidOverrideList: {
                twitter: 'twitter_out',
                snapchat: 'snapchat_out'
            }
        });

        onelinkGenerator.setCustomParameter("af_click_lookback", "af_click_lookback");
        onelinkGenerator.setCustomParameter("clickid", "clickid");
        onelinkGenerator.setCustomParameter("af_siteid", "af_siteid");
        onelinkGenerator.setCustomParameter("af_cost_model", "af_cost_model");
        onelinkGenerator.setCustomParameter("af_cost_value", "af_cost_value");
        onelinkGenerator.setCustomParameter("af_cost_currency", "af_cost_currency");

        let url = onelinkGenerator.generateUrl();
        if(!url){
            url = "https://diet-training.onelink.me/17FA/";
        }

        this.setState({url});
    }

    render(){
        return (
            <Layout page="pyramid" showMenu={false} showFooter={false}>
                <Seo
                    title="Piramida"
                >
                    <meta name="robots" content="noindex,nofollow" />
                </Seo>
                <AppBar
                    url={this.state.url}
                />
                <Hero hero={this.props.data.hero.childImageSharp.gatsbyImageData} />
                <Pyramid1 url={this.state.url} />
                <Pyramid2 />
                <Pyramid3 />
                <Pyramid4 url={this.state.url} />
                <Pyramid5 />
                <Pyramid6 url={this.state.url} />
                <Pyramid7
                    phones={this.props.data.phones.childImageSharp.gatsbyImageData}
                    url={this.state.url}
                />
                <Footer />
            </Layout>
        );    
    }
}

export default Page;

export const query = graphql`
    query {

        signet: file(relativePath: { eq: "icons/sygnet.png" }) {
            ...fixedImage62
        }

        hero: file(relativePath: { eq: "pyramid/hero.jpg" }) {
            ...fluidImage960
        }

        phones: file(relativePath: { eq: "pyramid/phones.png" }) {
            ...fluidImage960
        }
    }
`;
