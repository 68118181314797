import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

const Pyramid1 = (props) => (
        <section className="pyramid-1" id="piramida1">
            <Container>
                <Row>
                    <Col xs={{size: 12, offset: 0}} md={{size: 10, offset: 1}}>
                        <h2>Zapomnij o siłowni: te same efekty osiągniesz z tym 12-minutowym treningiem!</h2>
                        <p>Dlaczego szczupła sylwetka ciągle jest poza Twoim zasięgiem? Powiedzmy sobie szczerze - nie każdego z nas kręcą długie i ciężkie treningi na siłowni. Ale czy to naprawdę jedyny sposób, by osiągnąć swój wymarzony cel?</p>
                        <p>Zamiast znowu tam wracać i… rozczarować się, spróbuj czegoś zupełnie innego!</p>
    
                        <div className="text-center mt-5">
                            <a href={props.url} className="btn btn-primary">Pobierz aplikację</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
);

export default Pyramid1;
