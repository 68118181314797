import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

const Pyramid7 = (props) => (
    <section className="pyramid-7">
        <Container>
            <Row className="align-items-center">
                <Col xs={{size: 12, order: 2}} md={{size: 5, offset: 1, order: 1}}>
                    <GatsbyImage image={props.phones} />
                </Col>
                <Col xs={{size: 12, order: 1}} md={{size: 6, order: 2}} className="text-center grey-bg">
                    <h2>Jesteś gotowa na te efekty?</h2>
                    <p>Dołącz do ponad 500 000 osób, które już ćwiczą z&nbsp;aplikacją Diet&nbsp;&&nbsp;Training by Ann i osiągnij swój cel!</p>
                    <a href={props.url} className="btn btn-primary mt-4">Pobierz aplikację</a>
                </Col>
            </Row>
        </Container>
    </section>
);
    
export default Pyramid7;
